import { getContentBlockListName } from '..';
import { getProductElementData, trackProductElementClick, trackProductListImpressions } from '../../tracking/list';

/**
 * Initialise Bloomreach Recommendation Engine content blocks.
 *
 * This function must be called on pages that include Bloomreach Recommendation
 * Engine content blocks, but no more than once per page load.
 */
export const initBloomreachRecommendationBlocks = () => {
  // Add an IntersectionObserver to track impressions when a recommendation
  // block becomes visible in the viewport.
  const observer = new IntersectionObserver((entries) => {
    for (const entry of entries) {
      if (!entry.isIntersecting) {
        continue;
      }

      // Unobserve, so we only record impressions once per block.
      observer.unobserve(entry.target);

      const list = getContentBlockListName(entry.target);

      // Add click event listeners to each product in the block and get the
      // tracking data from the DOM.
      const productLinks = entry.target.querySelectorAll('.br-rec-engine-product');
      const items = [];
      for (const productLink of productLinks) {
        items.push(getProductElementData(productLink));

        productLink.addEventListener('click', (e) => trackProductElementClick(e, list));
      }

      // Record impressions for every product in the block, even those not
      // yet visible in the carousel.
      trackProductListImpressions({ list, items });
    }
  });

  document.querySelectorAll('.carousel--br-rec-engine').forEach((carousel) => {
    if (carousel.querySelector('[class*="js-carousel--br-rec-engine-block"].slick-initialized')) {
      observer.observe(carousel);
    } else {
      $(carousel).one('init', () => {
        observer.observe(carousel);
      });
    }
  });
};
